import React from "react";
import { Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import Header from "./Header";
import Footer from "./Footer";
import { postData } from "../http/request";
import v1 from "../Images/vector1.png";
import phone from "../Images/phone.png";
import logo from "../Images/logo.png";
import Group144 from "../Images/Group144.png";
import MobileHeader from "./Mobile/MobileMenu";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      name: "",
      email: "",
      message: "",
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  submit = () => {
    if (this.state.name == "" && this.state.email == "") {
      alert("Please enter Name and Email");
    } else {
      const data = {
        fullName: this.state.name,
        email: this.state.email,
        message: this.state.message,
      };
      postData("/support.mail.php", data);
      alert(
        "Thank you for contacting us. Your message  was received and we will respond soon"
      );
      this.props.history.push("/");
    }
  };
  render() {
    return (
      <>
        {/*Header Desktop*/}
        <Header history={this.props.history} className="desktop" />

        {/* Header Mobule */}
        <div className="container-fluid os mobile ">
          <Row className="justify-content-around mt-2 bb">
            <div className="col-5">
              <img
                src={logo}
                width="100%"
                onClick={() => {
                  this.props.history.push("/");
                }}
                className="cu"
              />
            </div>
            <div className="col-2">
              <img src={Group144} width="70%" onClick={this.toggle} />
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggle}
                className=""
              >
                <ModalHeader
                  toggle={this.toggle}
                  className="font-gill"
                ></ModalHeader>
                <ModalBody>
                  <MobileHeader history={this.props.history} />
                </ModalBody>
              </Modal>
            </div>
          </Row>
        </div>

        <div className="container-fluid os pt-5 pb-5 bc-d7">
          <div className="row justify-content-center mt-5">
            <div className="col-5">
              <div>
                <img src={v1} />
              </div>
              <div className="px48 f7 cl-bl">Contact Us</div>
            </div>
            <div className="col-5"></div>
          </div>
          <div className="row justify-content-center mt-5 vector4 pb-4">
            <div className="col-6 col-sm-3 mt-5">
              <img src={phone} width="90%" style={{ marginLeft: "14%" }} />
            </div>
            <div className="col-11 col-sm-6 contact-br">
              <div className="container pt-3 pb-5">
                <div className="row justify-content-center">
                  <div className="col-10">
                    <div className="f7 px24 mt-4">Need Support?</div>
                    <div className="px16 f4 mt-3">
                      Contact us if you need further assistance or more
                      information.
                    </div>
                    <label className="px16 f4 mt-3">Full Name</label>
                    <input
                      type="text"
                      className="form-control bc-24"
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      value={this.state.name}
                    ></input>
                    <label className="px16 f4 mt-3">Email Address</label>
                    <input
                      type="email"
                      className="form-control bc-24"
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                      value={this.state.email}
                    ></input>
                    <label className="px16 f4 mt-3">
                      Your request or message
                    </label>
                    <textarea
                      type="text"
                      className="form-control bc-24"
                      rows="3"
                      onChange={(e) => {
                        this.setState({ message: e.target.value });
                      }}
                      value={this.state.message}
                    ></textarea>
                    <div className="mt-2">
                      <button
                        className="sub-btn "
                        type="submit"
                        onClick={this.submit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer history={this.props.history} />
      </>
    );
  }
}

export default Contact;
