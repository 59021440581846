import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import {Row, Modal, ModalHeader, ModalBody} from "reactstrap";
import logo from "../Images/logo.png";
import Group144 from "../Images/Group144.png";
import MobileHeader from "./Mobile/MobileMenu";
import v1 from "../Images/vector1.png";
import icon1 from '../Images/telemedicine.png';
import icon2 from '../Images/consultant.png';
import icon3 from '../Images/monitor.png';
import icon4 from '../Images/health-check.png';
import icon5 from '../Images/application.png';
import icon6 from '../Images/icon6.png';



class Services extends React.Component{
   constructor(props){
       super(props);
       this.state = {
        modal : false,
       }
   }
    
   toggle = () => {
    this.setState({
        modal : !this.state.modal
    })
}
   render(){
   
       return(
       <>   
        {/*Header Desktop*/}
        <Header history={this.props.history} className="desktop"/>
        
        {/* Header Mobule */}
        <div className="container-fluid os mobile ">
             <Row className="justify-content-around mt-2 bb">
                 <div className="col-5">
                     <img src={logo} width="100%" onClick={()=>{this.props.history.push('/')}} className="cu"/>
                 </div>
                 <div className="col-2">
                 <img src={Group144} width="70%" onClick={this.toggle}/>
                         <Modal isOpen={this.state.modal} toggle={this.toggle} className="">
                             <ModalHeader  toggle={this.toggle} className="font-gill"></ModalHeader>                          
                                 <ModalBody >
                                      <MobileHeader history={this.props.history}/>
                                 </ModalBody>
                                                 
                         </Modal>
                 </div>
           </Row>
        </div>

       <div className="container-fluid os mt-5 pb-5">
             <div className="row justify-content-center mt-5">
                <div className="col-7 col-sm-5">
                    <div><img src={v1}/></div>
                    <div className="px48 f7 cl-bl">Our Services</div>
                </div>
                <div className="col-5">
                   
                </div>
             </div>
             <div className="row justify-content-center  vector4">
                 <div className="col-10 col-sm-4 mt-5">
                     <div className="servic-box text-center">
                         <div className="mt-4"><img src={icon1}/></div>
                         <div className="px20 f6 mt-2">Connectivity</div>
                         <div className="px16 f4 mt-3">Connecting patients and doctors<br/> over video conferencing</div>
                     </div>
                 </div>
                 <div className="col-10 col-sm-4 mt-5">
                     <div className="servic-box text-center">
                         <div className="mt-4"><img src={icon2}/></div>
                         <div className="px20 f6 mt-2">Consultations</div>
                         <div className="px16 f4 mt-3">Consultations with the best<br/> medical experts</div>
                     </div>
                 </div>
                 <div className="col-10 col-sm-4 mt-5">
                     <div className="servic-box text-center">
                         <div className="mt-4"><img src={icon3}/></div>
                         <div className="px20 f6 mt-2">Prescriptions</div>
                         <div className="px16 f4 mt-3">Prescriptions sent <br/>directly to your home or<br/> available for collection</div>
                     </div>
                 </div>    
             
             {/* <div className="row justify-content-center mt-5"> */}
                 <div className="col-10 col-sm-4 mt-5">
                     <div className="servic-box text-center">
                         <div className="mt-4"><img src={icon4}/></div>
                         <div className="px20 f6 mt-2">Creating A Network</div>
                         <div className="px16 f4 mt-3">Creating a wide network for <br/>medical practitioners to communicate</div>
                     </div>
                 </div>
                 <div className="col-10 col-sm-4 mt-5">
                     <div className="servic-box text-center">
                         <div className="mt-4"><img src={icon5}/></div>
                         <div className="px20 f6 mt-2">Cross-Platform Access</div>
                         <div className="px16 f4 mt-3">This is available on all<br/> devices as a web app</div>
                     </div>
                 </div>
                 <div className="col-10 col-sm-4 mt-5">
                     <div className="servic-box text-center">
                         <div className="mt-4"><img src={icon6}/></div>
                         <div className="px20 f6 mt-2">Availability</div>
                         <div className="px16 f4 mt-3">Medical care available in <br/>rural areas</div>
                     </div>
                 </div>  
             </div>  
             {/* </div> */}
       </div>
     
     
     {/* Footer */}
     <Footer history={this.props.history}/>
       </> 
       );
   }
}

export default Services;