import React from "react";
import { Row } from "reactstrap";
import logo from "../Images/logo.png";

class Header extends React.Component {
  constructor(props) {
    const activeNav = sessionStorage.getItem("active-nav");
    super(props);
    this.state = {
      activeLink: activeNav ? activeNav : "",
    };
  }

  _HandleClick = (value) => {
    sessionStorage.setItem("active-nav", value);
    this.setState({
      activeLink: value,
    });
  };

  render() {
    return (
      <>
        <div className="container-fluid os desktop">
          <Row className="justify-content-center mt-3 pb-3 bb">
            <div className="col-6">
              <img
                src={logo}
                onClick={() => {
                  this._HandleClick("");
                  this.props.history.push("/");
                }}
                className="cu"
              />
            </div>
            <div className="col-6">
              {/* <div className="row justify-content-between"> */}
              <div className="row">
                <div className="col-12">
                  <span
                    className={` px1 cl-b f4 cu  pt6 position-relative pd-26 ${
                      this.state.activeLink === "about" ? "act-link" : ""
                    }`}
                    onClick={() => {
                      this._HandleClick("about");
                      this.props.history.push("/about");
                    }}
                  >
                    About
                  </span>
                  <span
                    className={` px1 cl-b f4 cu  pt6 position-relative pd-26 ${
                      this.state.activeLink === "pre-enrolment"
                        ? "act-link"
                        : ""
                    }`}
                    onClick={() => {
                      this._HandleClick("pre-enrolment");
                      this.props.history.push("/pre-enrolment");
                    }}
                  >
                    Pre-Enrolment
                  </span>
                  <span
                    className={` px1 cl-b f4 cu  pt6 position-relative pd-26 ${
                      this.state.activeLink === "services" ? "act-link" : ""
                    }`}
                    onClick={() => {
                      this._HandleClick("services");
                      this.props.history.push("/services");
                    }}
                  >
                    Services
                  </span>
                  <span
                    className={` px1 cl-b f4 cu  pt6 position-relative pd-26 ${
                      this.state.activeLink === "contact" ? "act-link" : ""
                    }`}
                    onClick={() => {
                      this._HandleClick("contact");
                      this.props.history.push("/contact");
                    }}
                  >
                    Contact
                  </span>
                </div>
                <div className="col-4">
                  {/* <div className="row">
                    <div className="col-6 px16 cl-g f4 ">
                      <div
                        className="log-box"
                        onClick={() => {
                          window.location.href =
                            "https://wavetelemedicine.com/";
                        }}
                      >
                        Log in
                      </div>
                    </div>
                    <div className="col-6 px16 cl-g f4">
                      <div
                        className="sign-box"
                        onClick={() => {
                          this._HandleClick("");
                          this.props.history.push("/signup");
                        }}
                      >
                        Sign up
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              {/* </div> */}
            </div>
          </Row>
        </div>
      </>
    );
  }
}

export default Header;
