import React from "react";
import { Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import Header from "./Header";
import Footer from "./Footer";
import logo from "../Images/logo.png";
import Group144 from "../Images/Group144.png";
import MobileHeader from "./Mobile/MobileMenu";

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  render() {
    return (
      <>
        {/*Header Desktop*/}
        <Header history={this.props.history} className="desktop" />

        {/* Header Mobule */}
        <div className="container-fluid os mobile ">
          <Row className="justify-content-around mt-2 bb">
            <div className="col-5">
              <img
                src={logo}
                width="100%"
                onClick={() => {
                  this.props.history.push("/");
                }}
                className="cu"
              />
            </div>
            <div className="col-2">
              <img src={Group144} width="70%" onClick={this.toggle} />
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggle}
                className=""
              >
                <ModalHeader
                  toggle={this.toggle}
                  className="font-gill"
                ></ModalHeader>
                <ModalBody>
                  <MobileHeader history={this.props.history} />
                </ModalBody>
              </Modal>
            </div>
          </Row>
        </div>

        <div className="container-fluid os pt-5 pb-5 img-bc">
          <div className="row justify-content-center mt-5 pb-4">
            <div className="col-4"></div>
            <div className="col-11 col-sm-5 contact-br">
              <div className="container pt-3 pb-5">
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-10">
                    <div className="f7 px24 mt-4">Sign Up</div>
                    <form id="form" method="POST" action="save.php">
                      <div className="row mt-2">
                        <div className="col-12 col-sm-6 mt-3">
                          <label className="px16 f4 ">First Name</label>
                          <input
                            type="text"
                            className="form-control bc-24"
                            name="firstname"
                          ></input>
                        </div>
                        <div className="col-12 col-sm-6 mt-3">
                          <label className="px16 f4 ">Last Name</label>
                          <input
                            type="text"
                            className="form-control bc-24"
                            name="lastname"
                          ></input>
                        </div>
                      </div>

                      <label className="px16 f4 mt-3">Email Address</label>
                      <input
                        type="email"
                        className="form-control bc-24"
                        name="email"
                      ></input>
                      <label className="px16 f4 mt-3">Phone Number</label>
                      <input
                        type="number"
                        className="form-control bc-24"
                        name="phone"
                      ></input>
                      <label className="px16 f4 mt-3">
                        Country of residence
                      </label>
                      <input
                        type="text"
                        className="form-control bc-24"
                        name="country"
                      ></input>
                      <label className="px16 f4 mt-3">Password</label>
                      <input
                        type="password"
                        className="form-control bc-24"
                      ></input>

                      <button className="sub-btn mt-4" type="submit">
                        Sign Up
                      </button>

                      <div
                        className="px16 f7 text-center mt-5 cu"
                        onClick={() => {
                          this.props.history.push("/login");
                        }}
                      >
                        <span className="cl-b">Already have an account? </span>
                        <span className="cl-bl">Log in </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer history={this.props.history} />
      </>
    );
  }
}

export default SignUp;
