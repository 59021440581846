import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import About from "./Pages/AboutPage";
import Users from "./Pages/Admin/Users";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Login from "./Pages/Admin/Login";
import PreEnrolment from "./Pages/Pre_Enrolment";
import Services from "./Pages/Services";
import SignUp from "./Pages/Signup";
import Thanks from "./Pages/ThankuPage";
import DoctorDetail from "./Pages/Admin/DoctorDetail";

class App extends Component {
  render() {
    const App = () => (
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/pre-enrolment" component={PreEnrolment} />
            <Route exact path="/thanks" component={Thanks} />

            <Route exact path="/admin" component={Login} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/drdetail" component={DoctorDetail} />
          </Switch>
        </div>
      </Router>
    );
    return (
      <Switch>
        <App />
      </Switch>
    );
  }
}

export default App;
