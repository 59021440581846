import React from "react";
import {Row, Modal, ModalHeader, ModalBody} from "reactstrap";
import Header from "./Header";
import Footer from "./Footer";
import v1 from "../Images/vector1.png";
import img4 from '../Images/img4.png';
import v4 from "../Images/vector4.png";
import logo from "../Images/logo.png";
import Group144 from "../Images/Group144.png";
import MobileHeader from "./Mobile/MobileMenu";

class About extends React.Component{
   constructor(props){
       super(props);
       this.state = {
        modal : false,
       }
   }
    
   toggle = () => {
    this.setState({
        modal : !this.state.modal
    })
}
   render(){
   
       return(
       <>   
         {/*Header Desktop*/}
         <Header history={this.props.history} className="desktop"/>
        
        {/* Header Mobule */}
        <div className="container-fluid os mobile  pb-2">
             <Row className="justify-content-around mt-2 bb">
                 <div className="col-5">
                     <img src={logo} width="100%" onClick={()=>{this.props.history.push('/')}} className="cu"/>
                 </div>
                 <div className="col-2">
                 <img src={Group144} width="70%" onClick={this.toggle}/>
                         <Modal isOpen={this.state.modal} toggle={this.toggle} className="">
                             <ModalHeader  toggle={this.toggle} className="font-gill"></ModalHeader>                          
                                 <ModalBody >
                                      <MobileHeader history={this.props.history}/>
                                 </ModalBody>
                                                 
                         </Modal>
                 </div>
           </Row>
        </div>

       <div className="container-fluid os mt-5 pb-5 ">
             <div className="row justify-content-center mt-5">
                <div className="col-5">
                    <div><img src={v1}/></div>
                    <div className="px48 f7 cl-bl">About Us</div>
                </div>
                <div className="col-5"></div>
             </div>
             <div className="row justify-content-center mt-4 ">
                <div className="col-11 col-sm-5 mt-3">
                    <img src={img4} width='100%'/>
                </div>
                <div className="col-12 col-sm-5 mt-3">
                    <div className="px20 f7">
                        <span className="cl-bl">Wave Telemedicine</span>
                        <span className="cl-b"> is the platform that gives you<br/> access to connect to the best Doctors in 
                               the Medical<br/> field at the comfort of your home.The situation of<br/> the Covid-19
                                has affected the way we interact which<br/> has affected the Medical sector. 
                                Through our video <br/>conferencing you can be fully attended to and<br/> prescription 
                                sent to a pharmacy in your area.
                        </span>
                    </div>
                    <div className="about-v4 desktop">
                        <img src={v4}/>
                    </div>
                </div>
             </div>
       </div>
     
     
     {/* Footer */}
     <Footer history={this.props.history}/>
       </> 
       );
   }
}

export default About;