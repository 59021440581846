import React from "react";

class MobileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="text-center os">
        <div
          className="px20 cl-b f4 cu text-center pt6"
          onClick={() => {
            this.props.history.push("/about");
          }}
        >
          About
        </div>
        <div
          className="px20 cl-b f4 cu text-center pt6"
          onClick={() => {
            this.props.history.push("/services");
          }}
        >
          Services
        </div>
        <div
          className="px20 cl-b f4 cu text-center pt6"
          onClick={() => {
            this.props.history.push("/contact");
          }}
        >
          Contact
        </div>
        {/* <div className="px16 cl-g f4 ">
                     <div className='log-box mt-2' onClick={()=>{this.props.history.push('/login')}} style={{marginLeft:'auto',marginRight:'auto'}}>Log in</div>
                 </div> */}
        <div className="px16 cl-g f4">
          <div
            className="sign-box mt-2"
            onClick={() => {
              this.props.history.push("/pre-enrolment");
            }}
            style={{ marginLeft: "auto", marginRight: "auto" }}
          >
            Sign up
          </div>
        </div>
      </div>
    );
  }
}

export default MobileHeader;
