import React from "react";
import Swal from "sweetalert2";

class Thanks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    Swal.fire({
      title: "<strong>Thank You for Applying</strong>",
      icon: "success",

      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
      confirmButtonAriaLabel: "Thumbs up, great!",
      cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: "Thumbs down",
    });
    this.props.history.push("/");
  };
  render() {
    return <></>;
  }
}

export default Thanks;
