import React from "react";
import { Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import Header from "../Header";
import Footer from "../Footer";
import logo from "../../Images/logo.png";
import Group144 from "../../Images/Group144.png";
import MobileHeader from "../Mobile/MobileMenu";
import { postData } from "../../http/request";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      username: "",
      password: "",
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  nextPage = () => {
    const login = this.state;

    postData("/login.php", login).then((response) => {
      if (response.status === true) {
        sessionStorage.setItem("login", "admin");
        this.props.history.push("/users");
      } else {
        alert("Please give right User and Password");
      }
    });
  };
  render() {
    return (
      <>
        {/*Header Desktop*/}
        <Header history={this.props.history} className="desktop" />

        {/* Header Mobule */}
        <div className="container-fluid os mobile ">
          <Row className="justify-content-around mt-2 bb">
            <div className="col-5">
              <img
                alt="img"
                src={logo}
                width="100%"
                onClick={() => {
                  this.props.history.push("/");
                }}
                className="cu"
              />
            </div>
            <div className="col-2">
              <img src={Group144} alt="img" width="70%" onClick={this.toggle} />
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggle}
                className=""
              >
                <ModalHeader
                  toggle={this.toggle}
                  className="font-gill"
                ></ModalHeader>
                <ModalBody>
                  <MobileHeader history={this.props.history} />
                </ModalBody>
              </Modal>
            </div>
          </Row>
        </div>
        <div className="container-fluid os pt-5 pb-5 img-bc">
          <div className="row justify-content-center mt-5 pb-4">
            <div className="col-4"></div>
            <div className="col-11 col-sm-5 contact-br">
              <div className="container pt-3 pb-5">
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-10">
                    <div className="f7 px24 mt-4">Login</div>

                    <label className="px16 f4 mt-5">User</label>
                    <input
                      type="text"
                      className="form-control bc-24"
                      onChange={(e) => {
                        this.setState({ username: e.target.value });
                      }}
                      value={this.state.username}
                    ></input>
                    <label className="px16 f4 mt-3">Password</label>
                    <input
                      type="password"
                      className="form-control bc-24"
                      onChange={(e) => {
                        this.setState({ password: e.target.value });
                      }}
                      value={this.state.password}
                    ></input>

                    <div className=" mt-3" onClick={this.nextPage}>
                      <button className="sub-btn">Login</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer history={this.props.history} />
      </>
    );
  }
}

export default Login;
