import React from "react";
import logo from "../Images/logo.png";
import fb from "../Images/fb.png";
import gmail from "../Images/gmail.png";
import Group158 from "../Images/Group158.png";
import BackToTop from "react-back-to-top-button";
import WhatsApp from "../Images/whatsapp-icon.png";

class Footer extends React.Component {
  constructor(props) {
    const activeNav = sessionStorage.getItem("active-nav");
    super(props);
    this.state = {
      activeLink: activeNav ? activeNav : "",
    };
  }

  _HandleClick = (value) => {
    sessionStorage.setItem("active-nav", value);
    this.setState({
      activeLink: value,
    });
  };
  render() {
    return (
      <div className="container-fluid os col-b">
        <div className="row mt-5">
          <div className="col-12 col-sm-4 text-center mt-3">
            <img alt="" src={logo} />
          </div>
          <div className="col-5 col-sm-2 mt-3">
            <div className="f7 px16">Quick links</div>
            <div
              className="f4 px16 mt-3 cu"
              onClick={() => {
                this._HandleClick("about");
                this.props.history.push("/about");
              }}
            >
              About
            </div>
            <div
              className="f4 px16 mt-3 cu"
              onClick={() => {
                this._HandleClick("services");
                this.props.history.push("/services");
              }}
            >
              Service
            </div>
            <div
              className="f4 px16 mt-3 cu"
              onClick={() => {
                this._HandleClick("contact");
                this.props.history.push("/contact");
              }}
            >
              Contact
            </div>
          </div>
          <div className="col-7 col-sm-2 mt-3">
            <div className="f7 px16">Resources</div>
            <div className="f4 px16 mt-3 cu">Privacy Policy</div>
            <div className="f4 px16 mt-3 cu">Term and Conditions</div>
          </div>
          <div className="col-9 col-sm-2 mt-3">
            <div className="f7 px16">Contact</div>
            <div className="f4 px16 mt-3 cu">
              <a href="https://chat.whatsapp.com/DFHISmDUOM6GV2bXx66cWH">
                <img alt="" src={WhatsApp} style={{ width: "30px" }} />
              </a>
            </div>
            {/* <div className="f4 px16 mt-3 cu">
              <a href="https://chat.whatsapp.com/DFHISmDUOM6GV2bXx66cWH">
                <img alt="" src={WhatsApp} style={{ width: "30px" }} />
              </a>
            </div> */}
            <div className="f4 px16 mt-3 cu">support@telemedicine.com</div>
            <div>
              <span>
                <img alt="" src={fb} />
              </span>
              <span style={{ marginLeft: "5px" }}>
                <img alt="" src={gmail} />
              </span>
              <span style={{ marginLeft: "5px" }}>
                <img alt="" src={fb} />
              </span>
              <span style={{ marginLeft: "5px" }}>
                <img alt="" src={gmail} />
              </span>
            </div>
          </div>
          <div className="col-2 col-sm-1 mt-3">
            <BackToTop
              //  showOnScrollUp
              showAt={100}
              speed={1500}
              //  easing="easeInOutQuint"
            >
              <img alt="" src={Group158} />
              <div className="up-text f4 px14">Back to Top</div>
            </BackToTop>
          </div>
        </div>
        <div className="row mt-5 mb-3">
          <div className="text-center px14 f4">
            © 2021 | Telemedicine powered by Wavepalm
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
