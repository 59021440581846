import Axios from "axios";
const BASEURL = "https://telemed.wavepalmapps.com";
export var baseUrl = "https://nimc-app.herokuapp.com/api/";
export var telemedUrl = "https://telemedicine-server.herokuapp.com";

// get all inventory
export const getData = (URL, data) => {
  return new Promise((resolve, reject) => {
    fetch(BASEURL + URL) // getInventory
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        resolve(data);
      })
      .catch((error) => {
        console.error("error in post api", error);
        reject(error);
      });
  });
};

// add items in inventory
export const postData = (URL, data) => {
  return new Promise((resolve, reject) => {
    fetch(BASEURL + URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        resolve(data);
      })
      .catch((error) => {
        console.error("Error:", error);
        reject(error);
      });
  });
};

export var uploadFileService = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const req = new XMLHttpRequest();

      const formData = new FormData();
      //formData.append("uploadfile", data.uploadfile, data.fileName);

      formData.append("photo", file);
      //formData.append("uploadfile", data.uploadfile, data.fileName);
      req.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          let response = JSON.parse(this.responseText);
          console.log("----------created ------", response);
          resolve(response);
        }
      };
      req.open("POST", `${baseUrl}file-upload`);
      req.send(formData);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export var postData2 = (route, data) => {
  return new Promise((resolve, reject) => {
    Axios.post(telemedUrl + route, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
