import React from "react";
import { Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import Header from "./Header";
import Footer from "./Footer";
import v1 from "../Images/vector1.png";
import Group157 from "../Images/Group157.png";
import Group112 from "../Images/Group112.png";
import img1 from "../Images/img1.png";
import img2 from "../Images/img2.png";
import img3 from "../Images/img3.png";
import Group144 from "../Images/Group144.png";
import MobileHeader from "./Mobile/MobileMenu";
import logo from "../Images/logo.png";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  componentDidMount = () => {};
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  render() {
    return (
      <>
        {/*Header Desktop*/}
        <Header history={this.props.history} className="desktop" />

        {/* Header Mobule */}
        <div className="container-fluid os mobile  pb-2">
          <Row className="justify-content-around mt-2 bb">
            <div className="col-5">
              <img
                alt=""
                src={logo}
                width="100%"
                onClick={() => {
                  this.props.history.push("/");
                }}
                className="cu"
              />
            </div>
            <div className="col-2">
              <img alt="" src={Group144} width="70%" onClick={this.toggle} />
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggle}
                className=""
              >
                <ModalHeader
                  toggle={this.toggle}
                  className="font-gill"
                ></ModalHeader>
                <ModalBody>
                  <MobileHeader history={this.props.history} />
                </ModalBody>
              </Modal>
            </div>
          </Row>
        </div>

        {/* Body Part 1*/}
        <div
          className="container-fluid os e5 v4-mobile "
          style={{ height: "100vh" }}
        >
          <div className="row justify-content-center mt-5">
            <div className="col-11 col-sm-5" style={{ marginTop: "5%" }}>
              <div>
                <img alt="" src={v1} />
              </div>
              <div className="cl-bl px48 f7">
                Connect with the best
                <br /> doctors and get treated <br />
                at your convenience
              </div>
              <div className="f4 px20 cl-b mt-2">
                The platform that allows you to connect to the best <br />
                Doctors in the Medical field at the comfort of your home.
              </div>
              <div
                className="start-btn mt-4 px20"
                onClick={() => {
                  this.props.history.push("/pre-enrolment");
                }}
              >
                Get Started
              </div>
            </div>
            <div className="col-11 col-sm-5 ">
              <img alt="" src={Group157} width="100%" />

              <div className="star">
                <img alt="" src={Group112} />
              </div>
            </div>
          </div>
        </div>

        {/* Body Part 2*/}
        <div className="container-fluid os bc-23 pb-4 desktop">
          <div className="row pt-5">
            <div className="col text-center px40 f6 cl-b">What we offer?</div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-10 col-sm-4 two">
              <img alt="" src={img1} width="100%" />
            </div>
            <div className="col-11 col-sm-4 one">
              <div className="f6 px24 cl-b">Virtual Consultations</div>
              <div className="px16 f4 cl-b mt-2">
                We provide your consultations virtually (video and
                <br /> phone) and facilitate your care coordination
                <br /> electronically.
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-11 col-sm-4">
              <div className="f6 px24 cl-b">Flexible Payment Plans</div>
              <div className="px16 f4 cl-b mt-2">
                You can pay on the go or set up Medical insurance
                <br /> with the HMOs that partner with us. Whatever your
                <br /> health concerns are we have got you covered.
              </div>
            </div>
            <div className="col-10 col-sm-4">
              <img alt="" src={img2} width="100%" />
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-10 col-sm-4 two">
              <img alt="" src={img3} width="100%" />
            </div>
            <div className="col-11 col-sm-4 one">
              <div className="f6 px24 cl-b">Video conferencing </div>
              <div className="px16 f4 cl-b mt-2">
                Through our video conferencing you can be fully
                <br /> attended to by the best doctors and prescription sent
                <br /> to a pharmacy in your area.
              </div>
            </div>
          </div>
        </div>

        {/* Part 2 view on mobile */}
        <div className="container-fluid os bc-23 pb-4 mobile">
          <Row>
            <div className="col text-center px20 f6 cl-b mt-4">
              What we offer?
            </div>
            <div className="f6 px16 cl-b mt-3">Virtual Consultations</div>
            <div className="px14 f4 cl-b mt-2">
              We provide your consultations virtually (video and
              <br /> phone) and facilitate your care coordination
              <br /> electronically.
            </div>
          </Row>
          <Row className="justify-content-center bc-23 pt-2">
            <div className="col-10">
              <img alt="" src={img1} width="100%" />
            </div>
          </Row>
          <Row className="pt-4">
            <div className="f6 px16 cl-b">Flexible Payment Plans</div>
            <div className="px14 f4 cl-b mt-2">
              You can pay on the go or set up Medical insurance
              <br /> with the HMOs that partner with us. Whatever your
              <br /> health concerns are we have got you covered.
            </div>
          </Row>
          <Row className="justify-content-center pt-2">
            <div className="col-10">
              <img alt="" src={img2} width="100%" />
            </div>
          </Row>
          <Row className="pt-4">
            <div className="f6 px16 cl-b">Video conferencing </div>
            <div className="px14 f4 cl-b mt-2">
              Through our video conferencing you can be fully
              <br /> attended to by the best doctors and prescription sent
              <br /> to a pharmacy in your area.
            </div>
          </Row>
          <Row className="justify-content-center pt-2">
            <div className="col-10">
              <img alt="" src={img3} width="100%" />
            </div>
          </Row>
        </div>

        {/* Footer */}
        <Footer history={this.props.history} />
      </>
    );
  }
}

export default Home;
