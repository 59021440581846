import React from "react";
import { Row, Modal, ModalHeader, ModalBody, Input } from "reactstrap";
import Header from "../Header";
import Footer from "../Footer";
import Dialog from "./Dialog";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import logo from "../../Images/logo.png";
import Group144 from "../../Images/Group144.png";
import MobileHeader from "../Mobile/MobileMenu";
import { getData, postData } from "../../http/request";
import swal from "sweetalert";

class Users extends React.Component {
  state = { editorState: EditorState.createEmpty() };
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      records: [],
      records2: [],
      isOpen: false,
      message: "",
      editorState: "",
      loder: false,
      listOfArray: [],
    };
  }
  componentDidMount = () => {
    const data = sessionStorage.getItem("login");
    if (data != "admin") {
      this.props.history.push("/admin");
    } else {
      getData("/doctorregistration.php").then((response) => {
        console.log("Data==", response);
        this.setState({
          records: response,
          records2: response,
        });
      });
    }
  };
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  handleData = async (x) => {
    const data = {
      fullName: x.firstName + " " + x.lastName,
      email: x.email,
    };
    postData("/mail.php", data);

    await postData("/activation.php", { id: x.id });
    alert("Email has been sent");
    getData("/doctorregistration.php").then((response) => {
      this.setState({
        records: response,
      });
    });
  };

  sendMailAll = async (mes) => {
    console.log("mes==", mes);
    const timer = (ms) => new Promise((res) => setTimeout(res, ms));
    var emailData = [];
    for (var a = 0; a < this.state.records.length; a++) {
      const data = {
        fullName:
          this.state.records[a].firstName +
          " " +
          this.state.records[a].lastName,
        email: this.state.records[a].email,
        message: this.state.message,
      };

      emailData.push(data);
      postData("/mail2.php", data);
      console.log(emailData);
      await timer(3000);
    }
    this.setState({ isOpen: false });
  };

  handleHTML = () => {
    this.setState({
      loder: true,
    });
    const { editorState } = this.state;
    if (editorState) {
      const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      console.log(html);
      this.setState({
        message: html,
      });
      this.sendMailAll(html);
    }
  };
  // search name
  nameSearch = (e) => {
    console.log(e);
    const name = this.state.records2;
    const getAgent = name.filter((f) =>
      f.firstName.toLowerCase().startsWith(e)
    );
    this.setState({
      records: getAgent,
    });
  };
  drCall = (id) => {
    sessionStorage.setItem("drId", id);
    this.props.history.push("/drdetail");
  };
  //
  drDelete = (Id) => {
    swal({
      title: "Are you sure?",
      text: "You want to deactive this Doctor!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        postData("/deleteRecord.php", {
          id: Id,
        });
        swal("Poof! Your Doctor has been deleted!", {
          icon: "success",
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal("Your imaginary Doctor is safe!");
      }
    });
  };
  // add check list
  arryAdd = (id) => {
    let arr = this.state.listOfArray;
    const found = arr.find((element) => element === id);
    if (found === undefined) {
      arr.push(id);
    } else {
      const newArr = arr.filter((e) => e !== id);
      this.setState({
        listOfArray: newArr,
      });
    }
  };

  // delete all
  deleteAll = () => {
    swal({
      title: "Are you sure?",
      text: "You want to delete selected Doctors!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const listOfPatient = this.state.listOfArray;
        for (let a = 0; a < listOfPatient.length; a++) {
          postData("/deleteRecord.php", {
            id: listOfPatient[a],
          });
        }
        swal("Poof! Your Doctors have been deleted!", {
          icon: "success",
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        swal("Your Doctors are safe!");
      }
    });
  };
  render() {
    const { editorState } = this.state;
    // console.log(editorState ? editorState.getCurrentContent() : "null");
    return (
      <>
        {/*Header Desktop*/}
        <Header history={this.props.history} className="desktop" />

        {/* Header Mobule */}
        <div className="container-fluid os mobile ">
          <Row className="justify-content-around mt-2 bb">
            <div className="col-5">
              <img
                alt="img"
                src={logo}
                width="100%"
                onClick={() => {
                  this.props.history.push("/");
                }}
                className="cu"
              />
            </div>
            <div className="col-2">
              <img src={Group144} alt="img" width="70%" onClick={this.toggle} />
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggle}
                className=""
              >
                <ModalHeader
                  toggle={this.toggle}
                  className="font-gill"
                ></ModalHeader>
                <ModalBody>
                  <MobileHeader history={this.props.history} />
                </ModalBody>
              </Modal>
            </div>
          </Row>
        </div>
        <Dialog
          isOpen={this.state.isOpen}
          onClose={(e) => this.setState({ isOpen: false })}
        >
          <div>
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={this.onEditorStateChange}
            />
          </div>
          {this.state.loder === false && (
            <button
              type="button"
              class="btn btn-primary"
              onClick={this.handleHTML}
            >
              Send
            </button>
          )}
          {this.state.loder === true && (
            <button
              className="btn btn-success"
              type="button"
              disabled
              style={{ width: "20%" }}
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Sending...
            </button>
          )}
        </Dialog>
        <div className="container-fluid os pt-5 pb-5 img-bc">
          <div className="row justify-content-center  pb-4">
            <div
              className="col-11 col-sm-10 contact-br"
              style={{
                height: "70vh",
                overflowY: "auto",
                maxWidth: "100%",
                overflowX: "auto",
              }}
            >
              <div className="container pt-3 pb-5">
                <div className="row">
                  <div className="col-12 col-sm-10">
                    <div className="row">
                      <div className="col-8">
                        <span className="f7 px24 mt-4">All Users</span>
                        <span
                          className="f7 px16 mt-4"
                          style={{ marginLeft: "15px" }}
                        >
                          <button
                            className="user-active "
                            onClick={(e) => this.setState({ isOpen: true })}
                          >
                            Send Mail
                          </button>
                        </span>
                      </div>
                      <div className="col-4">
                        <Input
                          id="search-input"
                          placeholder="Search"
                          style={{
                            borderBottomLeftRadius: 4,
                            borderTopLeftRadius: 4,
                          }}
                          onChange={(e) => {
                            this.nameSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      {/* <div className="col-2">
                        <button
                          className="btn btn-primary"
                          onClick={this.activeAll}
                        >
                          Activete Slected
                        </button>
                      </div> */}

                      <div className="col-3">
                        <button
                          className="btn btn-primary"
                          onClick={this.deleteAll}
                        >
                          Delete Selection
                        </button>
                      </div>
                    </div>

                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">#</th>
                          <th scope="col">Status</th>
                          <th scope="col">FirstName</th>
                          <th scope="col">LastName</th>
                          <th scope="col">Email</th>
                          <th scope="col">PhoneNo</th>

                          <th scope="col">Graduation</th>
                          <th scope="col">Practice</th>
                          <th scope="col">CurrentPractice</th>
                          <th scope="col">Rank</th>
                          <th scope="col">MDCN</th>
                          <th scope="col">FOLIO</th>
                          <th scope="col">Specialization</th>

                          <th scope="col">Detail</th>
                          <th scope="col">Delete</th>
                        </tr>
                      </thead>
                      {this.state.records.map((x, index) => (
                        <tbody>
                          <tr>
                            <th>
                              <td>
                                <input
                                  className=""
                                  type="checkbox"
                                  value={x.id}
                                  onClick={(e) => {
                                    this.arryAdd(e.target.value);
                                  }}
                                ></input>
                              </td>
                            </th>
                            <th scope="row">{index + 1}</th>

                            {x.activation === "1" && <td> Activated </td>}
                            {x.activation === "0" && (
                              <td>
                                <button
                                  className="user-active"
                                  onClick={() => this.handleData(x)}
                                >
                                  Activate
                                </button>
                              </td>
                            )}

                            <td>{x.firstName}</td>
                            <td>{x.lastName}</td>
                            <td>{x.email} </td>
                            <td>{x.phone} </td>

                            <td>{x.gradYear}</td>
                            <td>{x.pracYear} </td>
                            <td>{x.currentState}</td>
                            <td>{x.rank}</td>
                            <td>{x.mdcnno}</td>
                            <td>{x.foliono}</td>
                            <td>{x.specialisation}</td>

                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  this.drCall(x.id);
                                }}
                              >
                                Detail
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn btn-danger"
                                onClick={() => {
                                  this.drDelete(x.id);
                                }}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer history={this.props.history} />
      </>
    );
  }
}

export default Users;
