import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { postData, getData } from "../../http/request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

class DoctorDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: [
        {
          id: "",
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          dob: "",
          gradYear: "",
          pracYear: "",
          currentState: "",
          rank: "",
          specialisation: "",
          workHours: "",
          timeRange: "",
          passport: "",
          license: "",
          foliono: "",
          mdcnno: "",
          activation: "",
        },
      ],
      // record: [],
    };
  }

  componentDidMount = async () => {
    const Id = await sessionStorage.getItem("drId");
    const drId = JSON.parse(Id);
    const data = { id: drId };
    const response = await postData("/getDoctorDetail.php", data);
    this.setState({
      record: response,
    });
  };

  render() {
    return (
      <>
        {/*Header Desktop*/}
        <Header history={this.props.history} className="desktop" />

        <div className="container-fluid os pt-5 pb-5 img-bc">
          <div className="row justify-content-center">
            <div className="col-11 bc-e5 bd-5">
              <div className="row mt-2">
                <div className="col-6">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.props.history.push("/users");
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      size="1x"
                      color="#323232"
                    />
                    Back
                  </div>
                </div>
              </div>
              <div className="row mb-2 pt-2">
                <div className="col-6">
                  <label className="fw-b">First Name</label>
                  <input
                    type="text"
                    className="form-control box-40"
                    value={this.state.record[0].firstName}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="fw-b">Last Name</label>
                  <input
                    type="text"
                    className="form-control box-40"
                    value={this.state.record[0].lastName}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="fw-b">Email</label>
                  <input
                    type="text"
                    className="form-control box-40"
                    value={this.state.record[0].email}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="fw-b">Phone No</label>
                  <input
                    type="text"
                    className="form-control box-40"
                    value={this.state.record[0].phone}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="fw-b">Date of Birth</label>
                  <input
                    type="text"
                    className="form-control box-40"
                    value={this.state.record[0].dob}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="fw-b">Graduation</label>
                  <input
                    type="text"
                    className="form-control box-40"
                    value={this.state.record[0].gradYear}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="fw-b">Practic</label>
                  <input
                    type="text"
                    className="form-control box-40"
                    value={this.state.record[0].pracYear}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="fw-b">Current Practice</label>
                  <input
                    type="text"
                    className="form-control box-40"
                    value={this.state.record[0].currentState}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="fw-b">Rank</label>
                  <input
                    type="text"
                    className="form-control box-40"
                    value={this.state.record[0].rank}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="fw-b">MDCN</label>
                  <input
                    type="text"
                    className="form-control box-40"
                    value={this.state.record[0].mdcnno}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="fw-b">Folio</label>
                  <input
                    type="text"
                    className="form-control box-40"
                    value={this.state.record[0].foliono}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="fw-b">Specialisation</label>
                  <input
                    type="text"
                    className="form-control box-40"
                    value={this.state.record[0].specialisation}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="fw-b">Hour</label>
                  <input
                    type="text"
                    className="form-control box-40"
                    value={this.state.record[0].workHours}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="fw-b">Time Range</label>
                  <input
                    type="text"
                    className="form-control box-40"
                    value={this.state.record[0].timeRange}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="fw-b">Photograph</label>
                  <div className="box box1">
                    <img src={this.state.record[0].passport}></img>
                  </div>
                </div>
                <div className="col-6">
                  <label className="fw-b">License</label>
                  <div className="box box2">
                    <img src={this.state.record[0].license}></img>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer history={this.props.history} />
      </>
    );
  }
}

export default DoctorDetail;
