import React from "react";
import { Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import Header from "./Header";
import Footer from "./Footer";
import logo from "../Images/logo.png";
import Group144 from "../Images/Group144.png";
import MobileHeader from "./Mobile/MobileMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { uploadFileService } from "../http/request";
import { postData, postData2, signUpMail } from "../http/request";

class PreEnrolment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      isPhotoChecked: false,
      isLicChecked: false,
      loder: false,
      files: [],
      doc: "",
      file: "",
      baseImageUrl: "https://nimc-app.herokuapp.com/api/file-upload",
      isDisable: false,
      license: "",
      passport: "",
      action: "",
      firstName: "",
      lastName: "",
      email: "",
      gradYear: "",
      pracYear: "",
      phone: "",
      specialisation: "",
      dob: "",
      currentState: "",
      rank: "",
      workHours: "",
      timeRange: "",
      foliono: "",
      mdcnno: "",
      password: "",
      country: "",
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (e.target.firstName.value === "") {
      alert("Please Enter Name");
    } else if (e.target.lastName.value === "") {
      alert("Please Enter LastName");
    } else if (e.target.email.value === "") {
      alert("Please Enter Email");
    } else if (e.target.gradYear.value === "") {
      alert("Please Enter Year of Graduation");
    } else if (e.target.pracYear.value === "") {
      alert("Please Enter Year of Practice");
    } else if (e.target.phone.value === "") {
      alert("Please Enter PhoneNumber");
    } else if (e.target.specialisation.value === "") {
      alert("Please Enter Specialization");
    } else {
      var _e = e;
      const details = {
        firstname: this.state.firstName,
        lastname: this.state.lastName,
        email: this.state.email,
        country: this.state.country,
        user: "Medical Expert",
        reg_number: "",
        password: this.state.password,
        hospitalType: "",
        role: "",
        speciality: this.state.specialisation,
        registration: "",
        phone: this.state.phone,
      };
      const mailToDoctor = {
        fullName: this.state.firstName + "" + this.state.lastName,
        email: this.state.email,
      };
      postData2("/register", details)
        .then((response) => {
          if (response.status === true) {
            postData("/mail.php", mailToDoctor);
            this.setState(
              {
                action: "register.php",
                loder: true,
              },
              () => {
                _e.target.submit();
              }
            );
          } else {
            alert(response.message);
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.request.status === 422) {
            alert(error.response.data.errors.email);
          } else if (error.request.status === 500) {
            alert(
              "Connection to internet lost, please check connection and retry"
            );
          } else {
            alert("Error creating account");
          }
        });
    }
  };

  imageHandler1 = (ev) => {
    if (!this.state.doc) {
      alert("Please select document type.");
      return false;
    }
    const reader = new FileReader();
    let file = ev.target.files[0];
    if (file && file.size / 1024 / 1024 > 1) {
      alert("File size exceeds 1 MB");
    } else {
      reader.readAsDataURL(file);
    }

    reader.onloadend = () => {
      this.setState({
        loder: true,
      });
      if (reader.readyState === 2) {
        this.setState({ profileImg1: reader.result, isDisable: true }, () => {
          uploadFileService(file).then(async (response) => {
            console.log(response);
            if (response.success) {
              this.checkTheDocuments(this.state.doc);
              let _files = [...this.state.files];
              if (this.state.doc === "passport") {
                const _f = `${response.Location}`;

                _files.push({ passport: _f });
                this.setState({
                  passport: _f,
                });
              } else if (this.state.doc === "license") {
                let _f = `${response.Location}`;
                _files.push({ license: _f });
                this.setState({
                  license: _f,
                });
              } else {
                alert("Please select document type.");
              }

              this.setState(
                {
                  files: _files,
                  isDisable: false,
                  loder: false,
                },
                () => {
                  //debugger;
                  sessionStorage.setItem(
                    "penfiles",
                    JSON.stringify(this.state.files)
                  );
                }
              );
            } else {
              console.error(response.error);
              alert("Unable upload file");
            }
          });
        });
      }
    };
  };

  checkTheDocuments = (docType) => {
    switch (docType) {
      case "license":
        this.setState({ isLicChecked: true });
        break;
      case "passport":
        this.setState({ isPhotoChecked: true });
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <>
        {/*Header Desktop*/}
        <Header history={this.props.history} className="desktop" />

        {/* Header Mobule */}
        <div className="container-fluid os mobile ">
          <Row className="justify-content-around mt-2 bb">
            <div className="col-5">
              <img
                alt=""
                src={logo}
                width="100%"
                onClick={() => {
                  this.props.history.push("/");
                }}
                className="cu"
              />
            </div>
            <div className="col-2">
              <img alt="" src={Group144} width="70%" onClick={this.toggle} />
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggle}
                className=""
              >
                <ModalHeader
                  toggle={this.toggle}
                  className="font-gill"
                ></ModalHeader>
                <ModalBody>
                  <MobileHeader history={this.props.history} />
                </ModalBody>
              </Modal>
            </div>
          </Row>
        </div>

        <div className="container-fluid os pt-5 pb-5 img-bc">
          <div className="row justify-content-center mt-5 pb-4">
            <div className="col-4"></div>
            <div className="col-11 col-sm-5 contact-br">
              <div className="container pt-3 pb-5">
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-10">
                    <div className="f7 px24 mt-4">
                      Doctor's Pre-registration Form
                    </div>
                    <form
                      id="form"
                      onSubmit={this.onSubmit}
                      method="POST"
                      action={this.state.action}
                    >
                      <div className="row mt-2">
                        <div className="col-12 col-sm-6 mt-3">
                          <label className="px16 f4 ">First Name</label>
                          <input
                            type="text"
                            className="form-control bc-24"
                            name="firstName"
                            onChange={(e) => {
                              this.setState({
                                firstName: e.target.value,
                              });
                            }}
                            value={this.state.firstName}
                          ></input>
                        </div>
                        <div className="col-12 col-sm-6 mt-3">
                          <label className="px16 f4 ">Last Name</label>
                          <input
                            type="text"
                            className="form-control bc-24"
                            name="lastName"
                            onChange={(e) => {
                              this.setState({
                                lastName: e.target.value,
                              });
                            }}
                            value={this.state.lastName}
                          ></input>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-12 col-sm-6 mt-3">
                          <label className="px16 f4 ">
                            Functional Email Address
                          </label>
                          <input
                            type="email"
                            className="form-control bc-24"
                            name="email"
                            onChange={(e) => {
                              this.setState({
                                email: e.target.value,
                              });
                            }}
                            value={this.state.email}
                          ></input>
                        </div>
                        <div className="col-12 col-sm-6 mt-3">
                          <label className="px16 f4 ">Date of Birth</label>
                          <input
                            type="date"
                            className="form-control bc-24"
                            name="dob"
                            onChange={(e) => {
                              this.setState({
                                dob: e.target.value,
                              });
                            }}
                            value={this.state.dob}
                          ></input>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-12 col-sm-6 mt-3">
                          <label className="px16 f4 ">Year of Graduation</label>
                          <input
                            type="number"
                            className="form-control bc-24"
                            name="gradYear"
                            onChange={(e) => {
                              this.setState({
                                gradYear: e.target.value,
                              });
                            }}
                            value={this.state.gradYear}
                          ></input>
                        </div>
                        <div className="col-12 col-sm-6 mt-3">
                          <label className="px16 f4 ">Year of Practice</label>
                          <input
                            type="text"
                            className="form-control bc-24"
                            name="pracYear"
                            onChange={(e) => {
                              this.setState({
                                pracYear: e.target.value,
                              });
                            }}
                            value={this.state.pracYear}
                          ></input>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-12 col-sm-6 mt-3">
                          <label className="px16 f4 ">
                            Current State of Practice
                          </label>
                          <input
                            type="text"
                            className="form-control bc-24"
                            name="currentState"
                            onChange={(e) => {
                              this.setState({
                                currentState: e.target.value,
                              });
                            }}
                            value={this.state.currentState}
                          ></input>
                        </div>
                        <div className="col-12 col-sm-6 mt-3">
                          <label className="px16 f4 ">Cadre or Rank</label>
                          <input
                            type="text"
                            className="form-control bc-24"
                            name="rank"
                            onChange={(e) => {
                              this.setState({
                                rank: e.target.value,
                              });
                            }}
                            value={this.state.rank}
                          ></input>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-12 col-sm-6 mt-3">
                          <label className="px16 f4 ">MDCN No</label>
                          <input
                            type="text"
                            className="form-control bc-24"
                            name="mdcnno"
                            onChange={(e) => {
                              this.setState({
                                mdcnno: e.target.value,
                              });
                            }}
                            value={this.state.mdcnno}
                          ></input>
                        </div>
                        <div className="col-12 col-sm-6 mt-3">
                          <label className="px16 f4 ">FOLIO No</label>
                          <input
                            type="text"
                            className="form-control bc-24"
                            name="foliono"
                            onChange={(e) => {
                              this.setState({
                                foliono: e.target.value,
                              });
                            }}
                            value={this.state.foliono}
                          ></input>
                        </div>
                      </div>
                      <label className="px16 f4 mt-3">Country</label>
                      <select
                        className="form-control bc-24"
                        name="country"
                        id="country"
                        onChange={(e) => {
                          this.setState({
                            country: e.target.value,
                          });
                        }}
                        value={this.state.country}
                      >
                        <option className="input-field">Select Country</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="USA">USA</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Ghana">Ghana</option>
                      </select>
                      <label className="px16 f4 mt-3">Password</label>
                      <input
                        type="password"
                        className="form-control bc-24"
                        name="password"
                        onChange={(e) => {
                          this.setState({
                            password: e.target.value,
                          });
                        }}
                        value={this.state.password}
                      ></input>
                      <label className="px16 f4 mt-3">Phone Number</label>
                      <input
                        type="number"
                        className="form-control bc-24"
                        name="phone"
                        onChange={(e) => {
                          this.setState({
                            phone: e.target.value,
                          });
                        }}
                        value={this.state.phone}
                      ></input>
                      <label className="px16 f4 mt-3">
                        Specialization/Department
                      </label>
                      <select
                        className="form-control bc-24"
                        name="specialisation"
                        onChange={(e) => {
                          this.setState({ specialisation: e.target.value });
                        }}
                        value={this.state.specialisation}
                      >
                        <option className="form-control bc-24">
                          Specialisation
                        </option>
                        <option value="Physician">General Physician</option>
                        <option value="Pediatrician">Pediatrician</option>
                        <option value="Gynecologist">Gynecologist</option>
                        <option value="Surgeon"> Surgeon</option>
                        <option value="Psychiatrist"> Psychiatrist</option>
                        <option value="Cardiologist"> Cardiologist</option>
                        <option value="Dermatologist">Dermatologist</option>
                        <option value="Endocrinologist">Endocrinologist</option>
                        <option value="Gastroenterologist">
                          Gastroenterologist
                        </option>
                        <option value="Endocrinologist">Nephrologist</option>
                        <option value="Ophthalmologist">Ophthalmologist</option>
                        <option value="Otolaryngologist">
                          Otolaryngologist
                        </option>
                        <option value="Pulmonologist">Pulmonologist</option>
                        <option value="Radiologist"> Radiologist</option>
                        <option value="Anesthesiologist">
                          Anesthesiologist
                        </option>
                        <option value="Oncologist"> Oncologist</option>
                        <option value="Dentist"> Dentist</option>
                      </select>
                      <label className="px16 f4 mt-3">
                        How many hours can you do per day?
                      </label>
                      <select
                        className="dropdown-toggle form-control bc-24"
                        name="workHours"
                        onChange={(e) => {
                          this.setState({
                            workHours: e.target.value,
                          });
                        }}
                        value={this.state.workHours}
                      >
                        <option>Please select hours</option>
                        <option>2</option>
                        <option>4</option>
                        <option>8</option>
                        <option>10</option>
                        <option>12</option>
                      </select>

                      <label className="px16 f4 mt-3">
                        What time range will be suitable?
                      </label>
                      <select
                        className=" form-control bc-24"
                        name="timeRange"
                        onChange={(e) => {
                          this.setState({
                            timeRange: e.target.value,
                          });
                        }}
                        value={this.state.timeRange}
                      >
                        <option>Please select time range</option>
                        <option>9am-1pm</option>
                        <option>1pm-5pm</option>
                        <option>5pm-9pm</option>
                        <option>9pm-1am</option>
                        <option>1am-5am</option>
                        <option>5am-9am</option>
                      </select>

                      <div className="row">
                        <label className="px16 f4 mt-3 mb-2">
                          Attach Corporate Passport Photograph and Practicing
                          License
                        </label>
                        <div className="col-4">
                          <label
                            htmlFor="input1"
                            className="folder-box bc-24 cu"
                            style={{ width: "100%" }}
                          >
                            <div>
                              <FontAwesomeIcon
                                icon={faFolder}
                                color="#3498DB"
                                size="3x"
                                className="mb-3 mt-3"
                                htmlFor="input1"
                                style={{ marginLeft: "30%" }}
                              />
                            </div>
                          </label>
                          <input
                            type="file"
                            name="image-upload"
                            id="input1"
                            accept="image/*"
                            onChange={this.imageHandler1}
                          ></input>
                        </div>
                        <div className="col-8">
                          <div className="">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                this.setState({ doc: e.target.value });
                              }}
                            >
                              <option>Select...</option>
                              <option value="passport">
                                Passport Photograph
                              </option>
                              <option value="license">
                                Practicing License
                              </option>
                            </select>
                            <div className="radio-color2">
                              <input
                                type="checkbox"
                                id="photo"
                                disabled="disable"
                                name="contributor"
                                checked={this.state.isPhotoChecked}
                              ></input>
                              <label for="photo" className="ml-2">
                                Passport Photograph
                              </label>
                              <br />
                              <input
                                type="checkbox"
                                id="license"
                                disabled="disable"
                                name="contributor"
                                checked={this.state.isLicChecked}
                              ></input>
                              <label for="license" className="ml-2">
                                Practicing License
                              </label>
                              {/* hide input */}
                              <input
                                id="hide"
                                name="passport"
                                value={this.state.passport}
                                // value="1"
                              ></input>
                              <input
                                id="hide"
                                name="license"
                                value={this.state.license}
                                //value="2"
                              ></input>
                              {/* .......... */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.loder === false && (
                        <div className="mt-2">
                          <button className="sub-btn " type="submit">
                            Submit
                          </button>
                        </div>
                      )}
                      {this.state.loder === true && (
                        <div className="row justify-content-center">
                          <div
                            className="spinner-border text-success"
                            role="status"
                          >
                            <span className="sr-only"></span>
                          </div>
                          <div
                            className="spinner-border text-success"
                            role="status"
                          >
                            <span className="sr-only"></span>
                          </div>
                          <div
                            className="spinner-border text-success"
                            role="status"
                          >
                            <span className="sr-only"></span>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer history={this.props.history} />
      </>
    );
  }
}

export default PreEnrolment;
